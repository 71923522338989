// Easingの追加
jQuery.easing.quart = function (x, t, b, c, d) {
	return -c * ((t=t/d-1)*t*t*t - 1) + b;
};
var chamber_chart_height=500;
$(document).ready(function(){
var browser=browserCheck();
var os=osCheck();
var device=deviceCheck();
$("html").addClass(browser).addClass(os);




//画面サイズごとの処理
var w = $(window).width();
deviceFunc(w);

$(window).resize(function(){
	var w = $(window).width();
	deviceFunc(w);
});

console.log(chamber_chart_height);
// <a href="#***">の場合、スクロール処理を追加
$('a[href*="#"]').click(function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		var $target = jQuery(this.hash);
		$target = $target.length && $target || jQuery('[name="' + this.hash.slice(1) +'"]');
		if ($target.length) {
			var targetOffset = $target.offset().top;
			$('html,body').animate({ scrollTop: targetOffset }, 800, 'quart');
			return false;
		}
	}
});

if($(".mh").length){
	$(".mh").matchHeight();
}
if($("#ticker").length){
	$.simpleTicker($('#ticker'),{
		'effectType':'roll',
		'speed':1500,
		'delay':6000
	});
}
if($(".key").length){
	/*var video=$("#video").get(0);
	var videoSrc="images/top_movie.mp4";
	video.src=videoSrc;
	video.addEventListener('loadstart', function(){ 
		console.log('loadstart');
		video.muted = true;
	});
	video.addEventListener('loadeddata', function(){ 
		console.log('loadeddata');
		$(".key01").addClass("show");
		$(".key01 .loading").delay(1000).queue(function() {
				$(this).addClass("hide").dequeue();
		});
		video.play();
	});
	*/
	
	var key=$('#slider').bxSlider({
		speed:800,
		easing:'cubic-bezier(.38,.05,.66,.92)',
		pause:8000,
		auto:true,
		infiniteLoop:false,
		controls:false,
		onSliderLoad: function(index){
			$(".key01").addClass("show");
		},
		onSlideAfter:function($slideElement, oldIndex, newIndex){
			//console.log(newIndex);
			if(oldIndex==0){
				$(".key01").removeClass("show");
				$(".key02").addClass("show");
			}else if(oldIndex==1){
				$(".key02").removeClass("show");
				$(".key01").addClass("show");
			}
		}
	});
}
$("#menu-btn").click(function(){
	$("#menu").toggleClass("open");
});

if($("#gmap").length){
	googlemap({
		"tit" : "Tokyo Research Center of Clinical Pharmacology Co., Ltd.",
		"lat" : 35.685283,
		"lng" : 139.720425,
		"maplat" : 35.685884, //mapの中心
		"maplng" : 139.720516, //mapの中心
		"zoom" : 17,
		"window" : "<dl id='infowin'><dt>Tokyo Research Center of Clinical Pharmacology Co., Ltd.</dt><dd>Yotsuya Medical Building, 20 Samoncho, Shinjuku-ku, Tokyo 1600017 Japan</dd></dl>"
	});
}

//実績紹介Chart
if($("#achieve").length){
	var ctx1 = document.getElementById("chart1").getContext('2d');
	var ctx2 = document.getElementById("chart2").getContext('2d');
	var myChart1 = new Chart(ctx1, {
		type: 'pie',
		data: {
				labels: ["P-I", "P-II", "P-III","BE","BA","Clinical research", "Specified clinical research"],
				datasets: [{
						data: [11, 7, 15, 15, 11, 30, 11],
						backgroundColor: ["#00578f","#0068ac","#0074bf","#1a82c6","#4097cf","#66acd9","#8cc0e2"],
						borderWidth: 0
				}]
		},
		options: {
			legend:{
				position:"right",
				labels:{
					boxWidth:12,
				},
				onClick: function(){ return false; }
			},
			tooltips:{
				backgroundColor:"#ffffff",
				bodyFontColor:"#555555",
				caretSize:4,
				cornerRadius:2,
				callbacks: {
					label: function (tooltipItem, data) {
						return data.labels[tooltipItem.index]
							+ ": "
							+ data.datasets[0].data[tooltipItem.index]
							+ "%"; //ここで単位を付けます
					}
				}
			}
		},
		plugins: [dataLabelPlugin]
	});
	var myChart2 = new Chart(ctx2, {
		type: 'pie',
		data: {
				labels: ["Vaccines","Topical dermal preparations","Central nervous system", "Anti-infection drugs", "Ophthalmology","Medical devices","Allergies","Other"],
				datasets: [{
						data: [18, 26, 7, 4, 4, 7, 4, 30],
						backgroundColor: ["#00578f","#0068ac","#0074bf","#1a82c6","#4097cf","#66acd9","#8cc0e2","#b3d6ec"],
						borderWidth: 0
				}]
		},
		options: {
			legend:{
				position:"right",
				labels:{
					boxWidth:12,
				},
				onClick: function(){ return false; }
			},
			tooltips:{
				backgroundColor:"#ffffff",
				bodyFontColor:"#555555",
				caretSize:4,
				cornerRadius:2,
				callbacks: {
					label: function (tooltipItem, data) {
						return data.labels[tooltipItem.index]
							+ ": "
							+ data.datasets[0].data[tooltipItem.index]
							+ "%"; //ここで単位を付けます
					}
				}
			}
		},
		plugins: [dataLabelPlugin]
	});
}
//稼働実績Chart
if($("#chamber.bn-achievement").length){
	var ctx = document.getElementById("chart").getContext('2d');
	ctx.canvas.height = chamber_chart_height;
	var myChart = new Chart(ctx, {
		type: 'bar',
		data: {
				labels: ["2006", "2007", "2008", "2009", "2010", "2011" ,"2012","2013","2014","2015","2016","2017"],
				datasets: [{
						label:"Clinical trials",
						data: [0,0,0,1,3,0,0,3,1,2,0,1],
						backgroundColor: "#67b9ee",
						borderWidth: 0
				},{
						label:"Clinical research",
						data: [4,2,3,0,1,2,2,3,1,0,0,0],
						backgroundColor: "#0074bf",
						borderWidth: 0
				}]
		},
		options: {
			maintainAspectRatio: false,
			tooltips:{
				enabled:false
			},
			legend: {
				display: true,
				labels:{
					boxWidth:12,
					padding:20
				},
				onClick: function(){ return false; }
			},
			scales: {
				xAxes:[{
					stacked: true
				}],
				yAxes: [{
					display: true,
					ticks: {
						min: 0,
						max: 7
					},
					stacked: true
				}],
			},
			layout: {
				padding: {
					top:0
				}
			}
		}
	});
	
}
if($(".validationform").length){
	//inputの下にエラー
	var validation = $(".validationform").exValidation({
		errInsertPos :"after",
		errPosition :"fixed",
		stepValidation: true,
		errHoverHide:false,
		errTipCloseBtn:false,
		scrollAdjust:0,
		errMsgPrefix : "",
	});
	if($("#chkprivacy").prop('checked')){
		$("#btn-submit").prop('disabled',false);
	}else{
		$("#btn-submit").prop('disabled',true);
	}
	$("#chkprivacy").click(function(){
		if($("#chkprivacy").prop('checked')){
			$("#btn-submit").prop('disabled',false);
		}else{
			$("#btn-submit").prop('disabled',true);
		}		
	});
	
	
	//Edgeでラジオボタンの値が消える対策
	//各ラジオボタンの設定（value値記録）
	$('input[type=radio]').on('change', function (e) {
			var $self = $(this);
			var valueData = $self.val();
			var nameData = $self.attr('name');
			var $myDataSaveField = $('[data-name=' + nameData + ']');
			$myDataSaveField.val(valueData);
	});

	//隠しフィールドの値から復帰処理
	$('[data-name]').each(function () {
			var $self = $(this);
			var valueData = $self.val();
			if(valueData === '') return false;
			var nameData = $self.attr('data-name');
			var $groupRadioButton = $('input[name=' + nameData + ']');
			$groupRadioButton.each(function () {
					var $self = $(this);
					if ($self.val() === valueData) {
							$self.prop('checked', true);
					}
			});
	});
	
}

//スクロール
$(window).scroll(function () {
	 var scrTop=$(this).scrollTop();//スクロール位置
	 if(scrTop>=100){
		 $(".pagetop").addClass("show");
	 }else{
		 $(".pagetop").removeClass("show");
	 }
});


});
var dataLabelPlugin = {
afterDatasetsDraw: function (chart, easing) {
		// To only draw at the end of animation, check for easing === 1
		var ctx = chart.ctx;

		chart.data.datasets.forEach(function (dataset, i) {
				var meta = chart.getDatasetMeta(i);
				if (!meta.hidden) {
						meta.data.forEach(function (element, index) {
								// Draw the text in black, with the specified font
								ctx.fillStyle = '#ffffff';

								var fontSize = 14;
								var fontStyle = 'normal';
								var fontFamily = 'Helvetica Neue';
								ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

								// Just naively convert to string for now
								var dataString = dataset.data[index].toString() + "%";

								// Make sure alignment settings are correct
								ctx.textAlign = 'center';
								ctx.textBaseline = 'middle';
									
								
								var position = element.tooltipPosition();
								var padx = (element._model.x-position.x)/2.5;
								var pady = (element._model.y-position.y)/2.5;
								var px = position.x + 3;
								var py = position.y - (fontSize / 2)+5;
								
								ctx.fillText(dataString, px - padx,py - pady);
						});
				}
				
							 
		});
}
};


//画面サイズごとの処理
function deviceFunc(w){
var bp = [599,960,1280];

if (w <= bp[0]) {
	//sp
	chamber_chart_height=280;
	$(".tel-number").each(function(){
		if(!$(this).parent(".tel-wrap").length){
			var no = $(this).data("tel");
			$(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
		}
	});
} else if(w <= bp[1]){
	//tb
	chamber_chart_height=400;
	$(".tel-wrap .tel-number").each(function(){
		$(this).unwrap();
	});
} else if(w <= bp[2]){
	//small pc
} else{
}
}
function googlemap(opt){
var latlng = new google.maps.LatLng(opt.lat,opt.lng);
var maplatlng = new google.maps.LatLng(opt.maplat,opt.maplng);
var myOptions = {
	zoom: opt.zoom, /*拡大比率*/
	center: maplatlng, /*表示枠内の中心点*/
	tilt:0,
	scrollwheel: false,
	mapTypeControl:false,
	mapTypeId: google.maps.MapTypeId.ROADMAP/*表示タイプの指定*/
};
var map = new google.maps.Map(document.getElementById('gmap'), myOptions);

/*アイコン設定▼*/
var markerOptions = {
	position: latlng,
	map: map,
	title: opt.tit
};
var marker = new google.maps.Marker(markerOptions);
　/*アイコン設定ここまで▲*/

/*情報ウィンドウ*/
var contentString=opt.window;
var infowindow=new google.maps.InfoWindow({
		content: contentString
});
infowindow.open(map,marker);//表示
/* マーカーがクリックされた時に情報ウィンドウ表示 */
google.maps.event.addListener(marker, 'click', function() {
		infowindow.open(map,marker);
});
}

/*ブラウザ判別*/
function browserCheck(){
var ua = window.navigator.userAgent.toLowerCase();
var av = window.navigator.appVersion.toLowerCase();
if(ua.indexOf('edge') != -1){
	return 'edge';
}else if (ua.indexOf('opera') != -1) {
	return 'opera';
}else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
	$("body").addClass('ie');
	if (av.indexOf("msie 6.") != -1) {
		return 'ie6';
	} else if (av.indexOf("msie 7.") != -1) {
		return 'ie7';
	} else if (av.indexOf("msie 8.") != -1) {
		return 'ie8';
	} else if (av.indexOf("msie 9.") != -1) {
		return 'ie9';
	}else if (av.indexOf("msie 10.") != -1) {
		return 'ie10';
	}else if (av.indexOf("rv:11.") != -1) {
		return 'ie11';
	}
} else if (ua.indexOf('chrome') != -1) {
	return 'chrome';
} else if (ua.indexOf('safari') != -1) {
	return 'safari';
} else if (ua.indexOf('gecko') != -1) {
	return 'firefox';
} else {
	return false;
}
}

/*OS判別*/
function osCheck(){
var ua = window.navigator.userAgent.toLowerCase();
if (ua.indexOf('windows') != -1) {
	if(ua.indexOf('phone') != -1){
		return 'winphone';
	}else{
		return 'win';
	}
}else if(ua.indexOf('iphone') != -1) {
	return 'iphone';
}else if(ua.indexOf('ipad') != -1) {
	return 'ipad';
}else if(ua.indexOf('mac') != -1) {
	return 'mac';
}else if(ua.indexOf('android') != -1) {
	if(ua.indexOf('mobile') != -1){
		if(ua.indexOf('A1_07') != -1){
			return 'tablet';
		}else if(ua.indexOf('SC-01C') != -1){
			return 'tablet';
		}else{	return 'android';}
	}else{
		return 'tablet';
	}
}else{
	return false;
}
}

/*デバイス判別*/
function deviceCheck(){
var ua = window.navigator.userAgent.toLowerCase();
if((ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1) 
		|| ua.indexOf("ipad") != -1
		|| (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1)
		|| (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1)
		|| ua.indexOf("kindle") != -1
		|| ua.indexOf("silk") != -1
		|| ua.indexOf("playbook") != -1){
		return 'tab';
	}else if((ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1)
		|| ua.indexOf("iphone") != -1
		|| ua.indexOf("ipod") != -1
		|| (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1)
		|| (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1)
		|| ua.indexOf("blackberry") != -1){
		return 'sp';
	}else{
		return 'pc';
	}
}